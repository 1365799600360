/* eslint-disable import/prefer-default-export */

//
// DOMAIN MODEL
//
export interface Message {
  id: string;
  author: Participant;
  message: string;
  timestamp: string;
  email: string;
}

export interface Participant {
  name: string;
  email: string;
  mobile: string;
}

export interface QA {
  id: string;
  sjekkpunktId: string;
  lastSeen: string;
  messages: Array<Message>;
  participants: Array<Participant>;
}

export interface Chat {
  _id: string;
  userId: string;
  tilstandsRapportId: string;
  updatedAt: string;
  userEmail: string;
  qas: Array<QA>;
}

//
// REDUX
//
export interface ChatState {
  readonly data: Chat;
  readonly pending: boolean;
  readonly error: Error;
}

export enum ChatActionTypes {
  REQUEST = '@@chat/REQUEST',
  SUCCESS = '@@chat/SUCCESS',
  ADD_QA_SUCCESS = '@@chat/ADD_QA_SUCCESS',
  FAILURE = '@@chat/FAILURE',
  ADD_QA_FAILURE = '@@chat/ADD_QA_FAILURE',
}

interface ChatRequest {
  type: ChatActionTypes.REQUEST;
}

interface ChatSuccess {
  type: typeof ChatActionTypes.SUCCESS;
  data: [];
}

interface AddQaSuccess {
  type: typeof ChatActionTypes.ADD_QA_SUCCESS;
  qa: {};
}

interface ChatFailure {
  type: typeof ChatActionTypes.FAILURE;
  error: Error;
}

interface AddQaFailure {
  type: typeof ChatActionTypes.ADD_QA_FAILURE;
  error: Error;
}

export type ChatActionCreators = ChatRequest | ChatSuccess | ChatFailure | AddQaSuccess | AddQaFailure | AddQaSuccess;
